import { cache, createAsync, useParams } from "@solidjs/router";
import { Accessor, createMemo, createSignal, onMount, Show } from "solid-js";
import { HubbleFullSvg, invoiceHeader } from "~/assets/assets";
import { usePluto } from "~/context/pluto_context";
import { clientRepo } from "~/server/apis/client_repo";
import { PlutoGiftDetailsRouteData } from "~/server/data/pluto_gift_details_route_data";
import {
  getPlutoInvoiceRouteData,
  PlutoInvoiceRouteData,
} from "~/server/data/pluto_invoice_route_data";
import { formatDateToDDMMMMYYYY } from "~/utils/date";
import { toRupees } from "~/utils/number";
import { DottedLoader } from "~/widgets/loader";

const getInvoiceRouteData$C = cache(
  getPlutoInvoiceRouteData,
  "pluto_gift_details"
);

export default function PlutoGiftCardInvoice() {
  const params = useParams();
  const { plutoGifts } = usePluto();

  const routeData: Accessor<PlutoInvoiceRouteData | undefined> = createAsync(
    () => getInvoiceRouteData$C(params.giftId),
    {
      deferStream: true,
    }
  );

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await clientRepo.getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  return (
    <Show when={giftCardDetails()} fallback={<DottedLoader color="#999" />}>
      <div class="flex h-full w-full flex-col overflow-y-auto md:w-[800px] lg:w-screen lg:bg-[#0A0A0A]">
        <div class="flex min-h-14 items-center justify-center border-b border-basePrimaryDark bg-black px-4 py-2">
          <p class="text-center text-bold  text-white">Invoice</p>
        </div>
        <div class="flex flex-col gap-6 p-5 lg:mx-auto lg:mt-6 lg:w-[375px] lg:rounded-2xl lg:border lg:border-baseSecondaryDark">
          <img src={invoiceHeader} class="rounded-xl" alt="" />
          <div>
            <p class="mb-2 text-medium text-textNormal">
              {formatDateToDDMMMMYYYY(
                new Date(giftCardDetails()?.paymentDetails.paymentDate!)
              )}
            </p>
            <p class="mb-1 text-bold text-white">Hi {plutoGifts.userName},</p>
            <p class="text-medium text-white">
              Thanks for purchasing gift worth{" "}
              {toRupees(giftCardDetails()?.budget!)} created using Pluto.
            </p>
          </div>
          <div class="flex flex-col gap-3 rounded-xl bg-[#1A1A1A] p-3">
            <p class="text-bold text-white">Item details</p>
            <div class="flex flex-col gap-2 border-t border-basePrimaryMedium pt-3">
              <div class="flex items-center justify-between">
                <p class="text-f12 text-textNormal">Item</p>
                <p class="text-f12 text-textNormal">No.</p>
              </div>

              <div class="flex items-end justify-between">
                <p class="text-mediumBold text-white">
                  Hubble gift worth {toRupees(giftCardDetails()?.budget!)}
                </p>
                <p class="text-mediumBold text-white">x 1</p>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-3 rounded-2xl bg-[#1A1A1A] p-3">
            <p class="text-bold text-white">Bill details</p>
            <div class="flex items-center justify-between">
              <p class="text-mediumBold text-white">Hubble gift</p>
              <p class="text-mediumBold text-white">
                {toRupees(giftCardDetails()?.budget!)}
              </p>
            </div>
            <div class="flex items-end justify-between border-t border-basePrimaryMedium pt-3">
              <div>
                <p class="text-mediumBold text-white">You paid</p>
                <p class="text-f12 text-textNormal">
                  Via {giftCardDetails()?.paymentDetails.paymentMode}
                </p>
              </div>
              <p class="text-h5 text-white">
                {toRupees(giftCardDetails()?.budget!)}
              </p>
            </div>
          </div>

          <div class="flex flex-col gap-2 border-t border-basePrimaryDark pt-6">
            <HubbleFullSvg class="h-4 w-[87.43px]" fill="#fff" />
            <p class="text-mediumBold text-white">
              Gullak technologies private limited
            </p>
            <p class="text-medium text-baseSecondaryMedium">
              Hubble Office, 3rd Floor, 1666/A, 14th main, Sector 3 HSR Layout,
              Bengaluru, Karnataka 560102
            </p>
          </div>
        </div>
      </div>
    </Show>
  );
}
